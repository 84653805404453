<script setup lang="ts">
import Modal from "@/components/helpers/Modal.vue";
import {defineAsyncComponent, ref, watch} from "vue";
const VideoBlock = defineAsyncComponent(() => import("@/components/blocks/VideoBlock.vue"))

interface Props {
	video: string
	portrait?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

let showModal = ref(false)
let isLoading = ref(false)

let video = ref(null)

function close() {
	console.log('we closing!')
	emit('close')
}



watch(() => props.show, (value) => {
	showModal.value = value;
})
</script>

<template>
	<Modal
		:show="showModal"
		:type="'video'"
		:orientation="props.portrait ? 'portrait' : 'landscape'"
		@close="close()">
		<template v-slot:content>
			<VideoBlock
				:video="props.video.videoId"
				:play="true"
			>
			</VideoBlock>
		</template>
	</Modal>
</template>
