<script setup lang="ts">
import {defineAsyncComponent, watch, onMounted, ref} from 'vue'
import {debounce} from 'vue-debounce'
import {getTranslation, getSettings} from '@/ts/utilities.ts';
import {getOrderLineGaItem, trackToDatalayer} from '@/ts/Helpers/tracking-helper.ts';
import {subscribeCustomerToNewsletter} from '@/ts/Helpers/newsletter.ts';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

const FAQ = defineAsyncComponent(() => import('@/components/blocks/FAQ.vue'))
const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))
const ProductLine = defineAsyncComponent(() => import('@/components/ProductLine.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const ShowModal = defineAsyncComponent(() => import('@/components/ShowModal.vue'))
const PaymentMethodLogos = defineAsyncComponent(() => import('@/components/blocks/PaymentMethodLogos.vue'))
const VoucherModal = defineAsyncComponent(() => import('@/components/VoucherModal.vue'))
const ShopSelectorModal = defineAsyncComponent(() => import('@/components/ShopSelectorModal.vue'))

const settings = getSettings()

const isLoading = ref(false)
const cartError = ref(null)
const cart = ref({})
const showVoucherModal = ref(false)
const orderLines = ref([])
const faqs = ref([])
const paymentMethods = ref([])
const shippingMethods = ref([])

const localShopName = ref('')
const localShopAddress = ref('')
const localShopEmail = ref('')
const cvr = ref('')

const terms = ref(false)
const customerName = ref('')
const customerAddress = ref('')
const customerEmail = ref('')
const customerZip = ref('')
const customerCity = ref('')
const customerPhone = ref('')

const customerSignupLocation = window.location.href
const formDataString = ref('')

const filteredShops = ref([])
const selectedShop = ref(null)
const showShopSelectorModal = ref(false)

const reloadAfterShippingMethodSet = ref(true)

function subscribe() {
	// Split name after first space to get first and last name
	let customerFirstName;
	let customerLastName;
	if (customerName) {
		[customerFirstName, customerLastName] = customerName.value.split(' ');
	}

	const formData = new FormData();

	// Map data to corresponding merge tags
	const data = {
		'EMAIL': customerEmail.value,
		'FNAME': customerFirstName,
		'LNAME': customerLastName,
		'ZIP': customerZip.value,
		'CITY': customerCity.value,
		'SIGNUP': customerSignupLocation,
		'GDPR': 'Accepteret'
	};
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			formData.append(key, data[key]);
		}
	}

	// Ignored, because TS doesn't accept formData as a string, because it can have a File object, but works as it should
	// More info: https://github.com/Microsoft/TypeScript/issues/30584
	// @ts-ignore
	formDataString.value = new URLSearchParams(formData).toString();

	subscribeCustomerToNewsletter(formDataString.value)
		.then(() => {
			// no need to show anything, since we're redirecting to a "Purchase" page.
			console.log('customer subscribed');
		})
}

function checkSubscribe() {
	if (terms.value) {
		subscribe()
	}

}

const alternativeDelivery = ref('0')
const deliveryMethod = ref(null)

const paymentMethod = defineModel({
	set(value) {
		const gaOrderlines = orderLines.value.map(ol => getOrderLineGaItem(ol))
		trackToDatalayer({
			event: 'add_payment_info',
			currency: settings.currencyCode,
			value: settings.cartCount,
			//coupon: this.VoucherInput(),
			payment_type: value,
			ecommerce: {
				items: gaOrderlines
			}
		});
	}
})

const updateShippingMethod = async (shippingMethodId) => {
	isLoading.value = true
	const formData = new FormData()
	formData.append('EcomCartShippingmethodID', shippingMethodId)


	await Promise.all([
		setShop(selectedShop.value?.MenuText, false),
		fetch(location.href, {
			method: 'post',
			body: formData
		})
	]).then(async () => {
		if(reloadAfterShippingMethodSet.value === true){
			window.location.reload()
		} else{
			reloadAfterShippingMethodSet.value = false;
			await getCartData()
		}
	})
}

const orderComment = ref('')

const cartOptions = JSON.stringify({
	'options': {
		'productDetailsSimple': true,
		'priceAndCampaign': true,
		'variantText': true,
		'variantOptionsAdvanced': true,
		'delivery': true,
		'media': true,
		'cylindo': true
	},
	'cartOptions': {
		'orderLines': true,
		'faqs': true,
		'shippings': true,
		'payments': true
	}
})

async function getCartData() {
	isLoading.value = true

	await fetch('/mobler-api/v2/ecom/cart/cartfeed', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: cartOptions
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			cart.value = data.cart
			orderLines.value = data.cart?.orderlines
			faqs.value = data.cart?.cartFaqs
			paymentMethods.value = data.cart?.paymentMethods
			shippingMethods.value = data.cart?.shippingMethods
			deliveryMethod.value = data.cart?.shippingMethodId
			setShopInfo()
		})
		.catch(error => {
			cartError.value = error
			console.error('There has been a problem with your fetch operation:', error)
		})

	isLoading.value = false
}

const getShopsByZip = async () => {
	filteredShops.value = []
	await fetch(`/mobler-api/v2/shops/getshops?ZipCode=${customerZip.value}`)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			if (data.shops.length > 1) {
				filteredShops.value = data.shops
			} else {
				selectedShop.value = data.shops[0]
				localShopName.value = data.shops[0].LocalShopName
				localShopAddress.value = data.shops[0].LocalShopAddress
				localShopEmail.value = data.shops[0].LocalShopEmail
				cvr.value = data.shops[0].Cvr
			}
		})
		.catch(error => {
			console.error('There has been a problem with your fetch operation:', error)
		})
}

const setShopInfo = () => {
	if (settings?.shopInfos[0]) {
		selectedShop.value = settings?.shopInfos[0]
		localShopName.value = settings?.shopInfos[0].LocalShopName
		localShopAddress.value = settings?.shopInfos[0].LocalShopAddress
		localShopEmail.value = settings?.shopInfos[0].LocalShopEmail
		cvr.value = settings?.shopInfos[0].Cvr
	}
}

const chooseShop = ref(settings?.shopInfos[0]?.MenuText)

function updateShop(value: string) {
	setShop(value)
}


const setShop = async (selectedShop: string, reload = true) => {
	await fetch(`mobler-api/v2/shops/setshop?ShopName=${selectedShop}&IsAboutUsPage=false&IsCheckoutPage=true`)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			if (data.success && reload) {
				window.location.reload()
			}
		})
		.catch(error => {
			console.error('There has been a problem with your fetch operation:', error)
		})
}

const populateCustomerData = () => {
	customerName.value = localStorage.getItem('customerName') || ''
	customerAddress.value = localStorage.getItem('customerAddress') || ''
	customerEmail.value = localStorage.getItem('customerEmail') || ''
	customerZip.value = localStorage.getItem('customerZip') || ''
	customerCity.value = localStorage.getItem('customerCity') || ''
	customerPhone.value = localStorage.getItem('customerPhone') || ''
}


const trackShippingInfo = () => {
	const gaOrderlines = orderLines.value.map(ol => getOrderLineGaItem(ol))
	trackToDatalayer({
		event: 'add_shipping_info',
		currency: settings.currencyCode,
		value: settings.cartCount,
		//coupon: cart.value.voucher,
		shipping_tier: cart.value.shippingMethodName,
		ecommerce: {
			items: gaOrderlines
		}
	});
}

onMounted(async () => {
	await getCartData()
	if (cart.value?.shippingMethodId && shippingMethods.value.filter(sh => sh.id === cart.value.shippingMethodId).length === 0) {
		reloadAfterShippingMethodSet.value = false;
		const cheapestShippingMethod = shippingMethods.value.reduce((cheapest, current) => {
			return current.price.raw < cheapest.price.raw ? current : cheapest
		})
		deliveryMethod.value = cheapestShippingMethod.id
	}

	populateCustomerData()
})

watch(customerZip, debounce((value: string) => {
	// Don't enable zip search on local webshop
	if (settings?.shopInfos[0]?.IsLocalWebshop || settings.shopname !== 'møblér') return

	if (value.length >= 4) {
		getShopsByZip()
	}
}, 225))

watch(chooseShop, (value) => {
	if (value) {
		updateShop(value)
	}
})

watch(deliveryMethod, async (value, oldValue) => {
	if (oldValue !== null && value !== oldValue) {
		await updateShippingMethod(value)
	}
})

watch([customerName, customerAddress, customerEmail, customerZip, customerCity, customerPhone], debounce(() => {
	localStorage.setItem('customerName', customerName.value)
	localStorage.setItem('customerAddress', customerAddress.value)
	localStorage.setItem('customerEmail', customerEmail.value)
	localStorage.setItem('customerZip', customerZip.value)
	localStorage.setItem('customerCity', customerCity.value)
	localStorage.setItem('customerPhone', customerPhone.value)
}, 225))


</script>

<template>
	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('Checkout.Updating') }}
	</Spinner>
	<div v-else-if="!orderLines || orderLines.length === 0"
		 class="container">
		<div class="flex flex-col gap-y-4 items-center justify-center min-h-[50vh]">
			<h2 class="text-center">{{ getTranslation('CheckoutNoLines') }}</h2>
			<RenderAction
				action="link"
				theme="primary"
				size="normal"
				:data="settings?.mainShopLink">
				{{ getTranslation('Checkout.GoToShop') }}
				<font-awesome-icon :icon="['fas', 'shop']"/>
			</RenderAction>
		</div>
	</div>
	<div v-else class="container">
		<div class="grid xl:grid-cols-4 gap-y-2 xl:gap-y-0 xl:gap-x-8 mb-4">
			<form class="xl:col-span-3 flex flex-col gap-y-8" name="ordersubmit" id="ordersubmit" method="post">
				<input type="hidden" name="EcomOrderCustomerCountry" id="EcomOrderCustomerCountry" value="DK"/>
				<input type="hidden" name="LocalShopEmail" value="" v-model="localShopEmail" />
				<input type="hidden" name="LocalShopName" value="" v-model="localShopName" />
				<input type="hidden" name="LocalShopAddress" value="" v-model="localShopAddress" />
				<input type="hidden" name="LocalShopCVR" value="" v-model="cvr" />

				<!-- Information -->
				<div class="flex flex-col gap-y-6 px-6 py-8 lg:px-8 lg:py-10 rounded bg-beige-light">
					<h3>{{ getTranslation('CheckoutDeliveryInformation') }}</h3>
					<div class="flex flex-col flex-wrap gap-2">
						<label>
							<span class="text-sm">{{ getTranslation('CheckoutFormNamePlaceholder') + ' *' }}</span>
							<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
								   name="EcomOrderCustomerName" ref="EcomOrderCustomerName" id="EcomOrderCustomerName"
								   required v-model="customerName" v-on:blur="trackShippingInfo"/>
						</label>
						<label>
							<span class="text-sm">{{ getTranslation('CheckoutFormAddressPlaceholder') + ' *' }}</span>
							<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
								   name="EcomOrderCustomerAddress" id="EcomOrderCustomerAddress" required
								   v-model="customerAddress" v-on:blur="trackShippingInfo"/>
						</label>

						<div class="flex gap-x-2">
							<label class="w-1/2 lg:w-1/4">
								<span class="text-sm">{{ getTranslation('CheckoutFormZipPlaceholder') + ' *' }}</span>
								<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
									   name="EcomOrderCustomerZip" id="EcomOrderCustomerZip" required
									   v-model="customerZip" v-on:blur="trackShippingInfo"/>
							</label>
							<label class="w-1/2 lg:w-full">
								<span class="text-sm">{{ getTranslation('CheckoutFormCityPlaceholder') + ' *' }}</span>
								<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
									   name="EcomOrderCustomerCity" id="EcomOrderCustomerCity" required
									   v-model="customerCity" v-on:blur="trackShippingInfo"/>
							</label>
						</div>
						<label>
							<span class="text-sm">{{ getTranslation('CheckoutFormPhonePlaceholder') + ' *' }}</span>

							<input class="form-input rounded-sm border border-beige mt-1 w-full" type="tel"
								   name="EcomOrderCustomerPhone" id="EcomOrderCustomerPhone" required pattern="[0-9]{8}"
								   title="8 cifret tlf. nummer" v-model="customerPhone" v-on:blur="trackShippingInfo"/>
							<small class="text-xs italic">{{ getTranslation('Checkout.Form.Phone.HelperText') }}</small>
						</label>
						<label>
							<span class="text-sm">{{ getTranslation('CheckoutFormEmailPlaceholder') + ' *' }}</span>

							<input class="form-input rounded-sm border border-beige mt-1 w-full" type="email"
								   name="EcomOrderCustomerEmail" id="EcomOrderCustomerEmail" required
								   v-model="customerEmail" v-on:blur="trackShippingInfo"/>
							<small class="text-xs italic">{{ getTranslation('Checkout.Form.Email.HelperText') }}</small>
						</label>

						<fieldset>
							<legend><strong>{{ getTranslation('CheckoutFormAltDeviveryAddress') }}</strong></legend>
							<div class="flex gap-x-4">
								<div>
									<label class="flex items-center gap-x-2">
										<input type="radio" name="EcomOrderAltDelivery" value="0" checked
											   v-model="alternativeDelivery"/>
										{{ getTranslation('Checkout.Form.Toggle.No') }}
									</label>
								</div>
								<div>
									<label class="flex items-center gap-x-2">
										<input type="radio" name="EcomOrderAltDelivery" value="1"
											   v-model="alternativeDelivery"/>
										{{ getTranslation('Checkout.Form.Toggle.Yes') }}
									</label>
								</div>
							</div>
						</fieldset>

						<div
							v-if="alternativeDelivery == '1'"
							class="flex flex-col flex-wrap gap-2"
						>
							<label>
								<span class="text-sm">{{ getTranslation('CheckoutFormNamePlaceholder') + ' *' }}</span>
								<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
									   name="EcomOrderDeliveryName" id="EcomOrderDeliveryName" required/>
							</label>
							<label>
								<span class="text-sm">{{ getTranslation('CheckoutFormAddressPlaceholder') + ' *' }}</span>
								<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
									   name="EcomOrderDeliveryAddress" id="EcomOrderDeliveryAddress" required/>
							</label>
							<div class="flex gap-x-2">
								<label class="w-1/2 lg:w-1/4">
									<span class="text-sm">{{ getTranslation('CheckoutFormZipPlaceholder') + ' *' }}</span>
									<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
										   name="EcomOrderDeliveryZip" id="EcomOrderDeliveryZip" required
										   pattern="[0-9]{4}"/>
								</label>
								<label class="w-1/2 lg:w-full">
									<span class="text-sm">{{ getTranslation('CheckoutFormCityPlaceholder') + ' *' }}</span>
									<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text"
										   name="EcomOrderDeliveryCity" id="EcomOrderDeliveryCity" required/>
								</label>
							</div>
						</div>
					</div>
				</div>

				<!-- Delivery -->
				<div class="flex flex-col gap-y-4 px-6 py-8 lg:px-8 lg:py-10 rounded bg-beige-light">
					<div class="flex items-center justify-between">
						<h3>{{ getTranslation('CheckoutPaymentInformation.DeliveryHeader') }}</h3>
						<ShowModal
							v-if="settings?.checkoutDeliveryTrustId"
							:fetch-from-api="true"
							:endpoint="'/dwapi/content/paragraphs/' + settings?.checkoutDeliveryTrustId">
							<font-awesome-icon :icon="['fal', 'truck']"/>
							{{ getTranslation('Checkout.Form.Delivery.Modal.Link') }}
						</ShowModal>
					</div>
					<div class="flex flex-col flex-wrap gap-2">
						<template v-if="settings?.shopInfos[0]?.IsLocalWebshop">
							<strong>{{ getTranslation('Checkout.YouAreShoppingWithManuallySelected') }}:</strong>
						</template>
						<template v-else>
							<!-- If we have multiple shops nearby -->
							<strong v-if="filteredShops.length > 1">
								{{ getTranslation('Checkout.SelectShop') }}:
							</strong>
							<!-- If we only have one shop nearby -->
							<strong v-else-if="selectedShop">
								{{ getTranslation('Checkout.YouAreShoppingWith') }}:
							</strong>
						</template>
						<ul class="list-none" v-if="selectedShop && !filteredShops.length">
							<li>{{ selectedShop.ShopName }}</li>
							<li>{{ selectedShop.Address }}</li>
							<li>{{ selectedShop.PostalAndCity }}</li>
						</ul>
						<div v-if="filteredShops">
							<fieldset>
								<div class="grid lg:grid-cols-3 gap-4">
									<div v-for="(shop, index) in filteredShops" :key="index">
										<label
											class="flex items-center gap-x-3 rounded border border-beige bg-white p-4 hover:bg-white-hover hover:cursor-pointer transition has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary">
											<input type="radio" name="SelectedShop" :value="shop.MenuText"
												   v-model="chooseShop" required/>
											<span>
												<strong>{{ shop.ShopName }}</strong><br>
												{{ shop.Address }}<br>
												{{ shop.PostalAndCity }}
											</span>
										</label>
									</div>
								</div>
							</fieldset>
						</div>
						<div class="self-start">
							<RenderAction
								action="button"
								theme="white"
								size="sm"
								@click="showShopSelectorModal = true"
							>
								{{ getTranslation('Checkout.SelectOtherShop') }}
								<font-awesome-icon :icon="['fal', 'arrow-up-right-from-square']"/>
							</RenderAction>

							<ShopSelectorModal
								:show="showShopSelectorModal"
								@close="showShopSelectorModal = false"
							/>
						</div>
					</div>

					<fieldset>
						<div class="flex flex-col gap-y-4">
							<div v-for="(shipping, index) in shippingMethods"
								 :key="index">
								<label
									class="flex items-center gap-x-3 rounded border border-beige bg-white p-4 hover:bg-white-hover transition has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary">
									<input type="radio" name="EcomCartShippingmethodID" :value="shipping.id"
										   v-model="deliveryMethod" required/>
									<span>
										<strong>{{ shipping.name }}</strong>
									</span>
									<span class="ml-auto">{{ shipping.price?.formatted }}</span>
								</label>
							</div>
						</div>
					</fieldset>
				</div>

				<!-- Payment -->
				<div class="flex flex-col gap-y-4 px-6 py-8 lg:px-8 lg:py-10 rounded bg-beige-light">
					<div class="flex items-center justify-between">
						<h3>{{ getTranslation('CheckoutPaymentInformation.PaymentHeader') }}</h3>
						<ShowModal
							v-if="settings?.checkoutPaymentTrustId"
							:fetch-from-api="true"
							:endpoint="'/dwapi/content/paragraphs/' + settings?.checkoutPaymentTrustId">
							<font-awesome-icon :icon="['fal', 'lock']"/>
							{{ getTranslation('Checkout.Form.Payment.Modal.Link') }}
						</ShowModal>
					</div>
					<div class="flex flex-col flex-wrap gap-y-4">
						<fieldset>
							<div class="flex flex-col gap-y-4">
								<div v-for="(payment, index) in paymentMethods"
									 :key="index">
									<label
										class="flex items-start gap-x-3 rounded border border-beige bg-white p-4 hover:bg-white-hover transition has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary">
										<input type="radio" class="mt-1 m" name="EcomCartPaymethodID"
											   :value="payment.id" v-model="paymentMethod" required/>
										<span class="flex flex-col gap-y-2">
											<strong>{{ payment.name }}</strong>
											<PaymentMethodLogos
												:payment-method-id="payment.id"
											/>
										</span>
										<span class="ml-auto">{{ payment.price }}</span>
									</label>
								</div>
							</div>
						</fieldset>
						<small class="text-xs">{{ getTranslation('CheckoutPaymentReservation') }}</small>
						<fieldset>
							<legend><strong>{{ getTranslation('CheckoutFormCommentLabel') }}</strong></legend>
							<div class="flex gap-x-4">
								<div>
									<label class="flex items-center gap-x-2">
										<input type="radio" name="EcomOrderComment" value="0" checked
											   v-model="orderComment"/>
										{{ getTranslation('Checkout.Form.Toggle.No') }}
									</label>
								</div>
								<div>
									<label class="flex items-center gap-x-2">
										<input type="radio" name="EcomOrderComment" value="1" v-model="orderComment"/>
										{{ getTranslation('Checkout.Form.Toggle.Yes') }}
									</label>
								</div>
							</div>
						</fieldset>
						<textarea v-if="orderComment == '1'" rows="2" name="EcomOrderCustomerComment"></textarea>
					</div>
				</div>

				<!-- Order Summary -->
				<div class="flex flex-col gap-y-4 px-6 py-8 lg:px-8 lg:py-10 rounded bg-primary-light">
					<div>
						<label class="inline-flex items-baseline gap-x-2">
							<input type="checkbox" name="EcomOrderCustomerAccepted" required/>
							<strong class="flex flex-col">
								{{ getTranslation('CheckoutAcceptConditionsText') }}
								<ShowModal
									:fetch-from-api="true"
									:endpoint="'/dwapi/content/paragraphs/' + settings?.handelsbetingelserId">
									{{ getTranslation('CheckoutAcceptConditions') }}
								</ShowModal>
							</strong>
						</label>
					</div>

					<div>
						<label class="inline-flex items-baseline gap-x-2">
							<input type="checkbox" id="newsletterCheckbox" name="newsletter" v-model="terms"/>
							<span>{{ getTranslation('CheckoutNewsletter') }}</span>
						</label>
					</div>

					<div class="flex flex-col gap-4">
						<div>
							<!-- TODO: checkout submission -->
							<!-- TODO: Black Friday -  @(BlackFridayTheme == "True" ? "bf-bg-black" : "") -->
							<RenderAction
								action="button"
								theme="primary"
								size="lg"
								name="CartV2.GotoStep1"
								id="CartV2.GotoStep1"
								type="submit"
								@click="checkSubscribe"
							>
								<h3>{{ getTranslation('CheckoutGoToPayment') }}</h3>
								<font-awesome-icon :icon="['fas', 'arrow-right']" class="text-2xl"/>
							</RenderAction>
						</div>
						<div class="flex gap-x-2">
							<font-awesome-icon :icon="['fal', 'lock']"/>
							<small class="text-xs">
								{{ getTranslation('CheckoutNewWindowHelperText') }}
							</small>
						</div>
					</div>
				</div>
			</form>

			<div class="flex flex-col">
				<div class="sticky top-4 flex flex-col gap-y-4">
					<div class="border border-grey rounded overflow-hidden">
						<div class="flex items-center justify-between border-b p-6 border-b-grey">
							<h4>{{ getTranslation('Checkout.OrderSummaryLabel') }}</h4>
							<RenderAction
								action="link"
								theme="white"
								size="sm"
								:data="settings?.cartPage">
								<font-awesome-icon :icon="['fal', 'pen']"/>
								{{ getTranslation('Checkout.EditCart') }}
							</RenderAction>
						</div>
						<div class="flex flex-col">
							<template v-for="(orderLine, index) in orderLines"
									  :key="orderLine.id"
							>
								<ProductLine
									:product="orderLine"
									:simple="true"
									:padding="true"
								/>
							</template>
						</div>
						<div class="border-b border-b-grey">
							<div class="flex flex-col-reverse lg:flex-row gap-4 py-4 px-6">
								<ul class="list-none flex flex-col w-full">
									<li class="flex justify-between">
										<span>{{ getTranslation('CheckoutTotalNoFees') }}:</span>
										{{ cart.totalNoFees }}
									</li>
									<li class="flex justify-between">
										<span>{{ getTranslation('CheckoutDelivery') }}:</span>
										{{ cart.shippingFee }}
									</li>
									<li class="flex flex-col mt-2 gap-2">
										<div class="flex justify-between">
											<strong class="text-2xl">{{ getTranslation('CheckoutTotalPrice') }}:</strong>
											<strong class="text-2xl">{{ cart.totalInclFees }}</strong>
										</div>
										<div class="italic text-sm text-right">
											<span>{{ getTranslation('CheckoutVatTotal') }}:</span>
											{{ cart.vatTotal }}
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="flex gap-x-2 py-4 px-6">
							<strong>{{ getTranslation('Checkout.GotVoucher') }}</strong>
							<RenderAction
								type="button"
								theme="link"
								size="none"
								@click="showVoucherModal = true"
							>
								{{ getTranslation('Checkout.ApplyVoucherHere') }}
							</RenderAction>

							<VoucherModal
								:show="showVoucherModal"
								@close="showVoucherModal = false"
							/>
						</div>
					</div>

					<FAQ
						v-if="faqs.length > 0"
						:title="getTranslation('FAQ.Title')"
						:data="faqs"
						type="list"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
