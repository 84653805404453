<script setup lang="ts">
import {defineAsyncComponent, ref, watch} from 'vue'
import {getTranslation, getSettings} from '@/ts/utilities.ts'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const Modal = defineAsyncComponent(() => import('@/components/helpers/Modal.vue'))
const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const Recommendations = defineAsyncComponent(() => import('@/components/Recommendations.vue'))

interface Props {
	show: boolean,
	product: object,
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const settings = getSettings()

let showModal = ref(false)
let isLoading = ref(false)


function close() {
	emit('close')
}

watch(() => props.show, (value) => {
	showModal.value = value;
})
</script>

<template>
	<Modal :show="showModal" @close="close()" theme="powerstep">
		<template v-slot:modal-title>
			<p class="text-xl md:text-2xl">{{ getTranslation('MiniCartHeader') }}</p>
		</template>
		<template v-slot:content>
			<Spinner v-if="isLoading"
					 class="w-full">
				{{ getTranslation('cartUpdating') }}
			</Spinner>
			<div v-else
				 class="flex flex-col w-full">
				<div class="flex flex-wrap lg:flex-row gap-y-4 px-4 md:px-8 py-6 xl:gap-2 items-center justify-center lg:justify-between">
					<div class="flex items-center gap-4">
						<div class="flex justify-center align-center max-w-[10rem] h-auto relative">
							<img class="object-contain aspect-[4/3]"
								 v-if="product?.media?.images[0] || product?.media?.productImage"
								 :src="product?.cylindo?.isUsingCylindoAssets ?
									product?.media?.productImage :
									'/Admin/Public/GetImage.ashx?Image=' + product?.media?.images[0].url + '&Width=280&height=220&Format=webP&Quality=90&Crop=5&resolution=50'"
								 :alt="product?.name"
								 width="280"
								 height="220"
								 loading="lazy"
							/>
						</div>
						<div class="flex flex-col">
							<p>{{ props.product?.name }}</p>
							<strong>{{ product?.priceAndCampaign?.price.formatted }}</strong>
						</div>
					</div>
					<div class="flex flex-col gap-y-2">
						<div class="flex gap-x-2">
							<RenderAction
								action="button"
								theme="white"
								@click="close()"
							>
								{{ getTranslation('Checkout.ContinueShopping') }}
							</RenderAction>
							<RenderAction
								v-if="settings?.cartPage"
								action="link"
								theme="white"
								:data="settings.cartPage"
							>
								{{ getTranslation('Cart.GoToCart') }}
								<font-awesome-icon :icon="['fal', 'cart-shopping']"/>
							</RenderAction>
						</div>
						<RenderAction
							v-if="settings?.checkoutPage"
							action="link"
							theme="primary"
							:data="settings.checkoutPage"
						>
							{{ getTranslation('Checkout.ShowCart.NextStep') }}
							<font-awesome-icon :icon="['fal', 'arrow-right']"/>
						</RenderAction>
					</div>
				</div>

				<!-- TODO: Tilføj fragt-beregner -->
<!--				<NotificationElement-->
<!--					theme="success">-->
<!--					<template #content>-->
<!--						<font-awesome-icon :icon="['fal', 'truck']" />-->
<!--						&lt;!&ndash; TODO: Translation &ndash;&gt;-->
<!--						<p><strong>Tillykke!</strong> Du har opnået gratis fragt</p>-->
<!--					</template>-->
<!--				</NotificationElement>-->

				<div class="hidden lg:flex justify-between items-center gap-y-2 w-full">
					<Recommendations
						type="purchasedwith"
						:product-id="product.id"
						:variant-id="product.variantId"
						:take="8"
						:contain="true"
					/>
				</div>
			</div>
		</template>
	</Modal>
</template>
