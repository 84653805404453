<script setup lang="ts">
import { defineAsyncComponent, onMounted, onBeforeUnmount } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))

const props = defineProps({
	show: Boolean,
	loading: Boolean,
	exit: {
		type: String,
		default: 'Luk'
	}
})
const emit = defineEmits(['close'])

function keydownHandler(event: { key: string; }) {
	if(!props.show) return

	if (event.key === 'Escape') {
		close()
	}
}

function close() {
	emit('close')
}

// When modal is opened, append the overflow-hidden class to the html element
// watch(props.show, (value) => {
//   if (value) {
//     document.documentElement.classList.add('overflow-hidden', 'modal-open')
//   } else {
//     document.documentElement.classList.remove('overflow-hidden', 'modal-open')
//   }
// })

onMounted(() => {
	// Listen for keydown events
	window.addEventListener('keydown', keydownHandler)
})

onBeforeUnmount(() => {
	// Remove keydown event listener
	window.removeEventListener('keydown', keydownHandler)
})

</script>

<template>
	<div
		v-show="show"
		ref="modal"
		class="items-center overflow-hidden z-40 fixed inset-0"
		:class="{ 'flex': show, hidden: !show }"
		role="dialog"
		:aria-hidden="show ? 'false' : 'true'"
		aria-labelledby="popup-headline"
		aria-describedby="popup-description"
	>
		<div
			class="absolute inset-0 bg-black opacity-50"
			aria-hidden="true"
			@click="close"
		></div>
		<div
			class="m-auto w-full lg:w-[calc(100vw-1rem)] xl:w-[calc(1280px-2rem)] 2xl:w-[calc(1536px-2rem)] bg-white absolute lg:top-[42px] top-0 lg:-left-8 lg:-right-8 inset-x-0"
			>
			<div class="flex flex-col lg:flex-row">
				<div class="flex bg-white w-full h-[96px] lg:hidden"></div>
				<aside class="p-8 lg:pt-24 lg:w-[24rem] xl:w-[26rem] 2xl:w-[32rem] bg-white overflow-y-scroll overflow-hiddenbar hidden lg:block">
					<slot name="popular"></slot>
				</aside>

				<div class="min-h-[80vh] max-h-[90vh] lg:max-h-[90vh] flex-1 px-4 py-8 lg:py-0 pb-20 lg:px-8 lg:pb-8 bg-grey-light overflow-y-scroll overflow-hiddenbar">
					<div class="hidden lg:flex  justify-between py-8">
						<slot name="modal-title"></slot>
						<button
							type="button"
							class="justify-self-end ml-auto px-3 text-base hover:underline"
							@click="close"
						>
							<span class="mr-2 hidden lg:inline-flex">{{ exit }}</span>
							<font-awesome-icon :icon="['fal', 'close']" />
						</button>
					</div>

					<Spinner v-if="loading">
						Søger efter produkter...
					</Spinner>
					<div v-else>
						<slot name="content">
							Search results
						</slot>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
