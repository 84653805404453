<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'
import {getTranslation, getSettings} from '@/ts/utilities.ts'
import {deleteOrderLine, updateOrderLine, checkVoucher} from '@/ts/Helpers/cart-helper.ts'
import {getOrderLineGaItem, trackToDatalayer} from '@/ts/Helpers/tracking-helper.ts'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))
const PaymentMethodLogos = defineAsyncComponent(() => import('@/components/blocks/PaymentMethodLogos.vue'))
const ProductLine = defineAsyncComponent(() => import('@/components/ProductLine.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const FAQ = defineAsyncComponent(() => import('@/components/blocks/FAQ.vue'))
const VoucherModal = defineAsyncComponent(() => import('@/components/VoucherModal.vue'))
const Recommendations = defineAsyncComponent(() => import('@/components/Recommendations.vue'))

import {ProductData} from '@/interfaces/ProductInterface.ts'

const product: ProductData = ref(<ProductData>{})

const isLoading = ref(false)
const showVoucherModal = ref(false)
const cartError = ref(null)
const cart = ref({})
const orderLines = ref([])
const usps = ref([])
const faqs = ref([])
const shopInformation = ref(null);

const settings = getSettings()

const cartOptions = JSON.stringify({
	'options': {
		'productDetailsAdvanced': true,
		'priceAndCampaign': true,
		'variantText': true,
		'variantOptionsAdvanced': true,
		'delivery': true,
		'media': true,
		'cylindo': true
	},
	'cartOptions': {
		'orderLines': true,
		'usps': true,
		'faqs': true
	}
})
async function getCartData() {
	isLoading.value = true

	await fetch('/mobler-api/v2/ecom/cart/cartfeed', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: cartOptions
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			if(data.cart) {
				cart.value = data.cart
				orderLines.value = data.cart.orderlines
				usps.value = data.cart.cartUsps
				faqs.value = data.cart.cartFaqs
			}
		})
		.catch(error => {
			cartError.value = error
			console.error('There has been a problem with your fetch operation:', error)
		})

	isLoading.value = false
}

const orderLineDeletion = (orderLine) => {
	isLoading.value = true
	deleteOrderLine(cartOptions, orderLine.id).then(data => {
		if (data.cart) {
			cart.value = data.cart
			orderLines.value = data.cart.orderlines
		} else {
			// If we delete the last orderline in the cart, we receive an empty cart object, so let's reset the cart and orderlines
			cart.value = {}
			orderLines.value = []
		}

		isLoading.value = false

		trackToDatalayer({
			event: 'remove_from_cart',
			ecommerce: {
				items: [
					getOrderLineGaItem(orderLine)
				]
			}
		})
	})
}

const orderLineUpdate = (orderLine, quantity) => {
	isLoading.value = true
	updateOrderLine(cartOptions, orderLine.id, quantity).then(data => {
		if (data.cart) {
			cart.value = data.cart
			orderLines.value = data.cart.orderlines
		} else {
			// If we delete the last orderline in the cart, we receive an empty cart object, so let's reset the cart and orderlines
			cart.value = {}
			orderLines.value = []
		}
		isLoading.value = false

		const updatedOrderLine = orderLines.value.find(ol => ol.id === orderLine.id)
		if(!updatedOrderLine || updatedOrderLine.quantity < orderLine.quantity){
			trackToDatalayer({
				event: 'remove_from_cart',
				ecommerce: {
					items: [
						getOrderLineGaItem(orderLine)
					]
				}
			})
		} else{
			trackToDatalayer({
				event: 'add_to_cart',
				ecommerce: {
					items: [
						getOrderLineGaItem(orderLine)
					]
				}
			})
		}
	})
}

const voucherDeletion = () => {
	checkVoucher()
		.then(data => {
			// This actually returns an error, because we're submitting an empty value to DW, but it's the official way of removing a voucher
			if (data.status === 'Error') {
				setTimeout(() => {
					window.location.reload()
				}, 2000)
			}
		})
		.catch(error => {
			console.error('There has been a problem with your fetch operation:', error)
		})
}

const hasOrderlineDiscount = () => {
	return orderLines.value.filter(ol => ol.orderlinePriceInfo.isDiscountLine).length > 0
}

const setShopOwnerImage = () => {
	let shopOwnerImage = shopInformation.value[0].ShopOwnerImageFull ? shopInformation.value[0].ShopOwnerImageFull : shopInformation.value[0].ShopOwnerImage

	return shopOwnerImage ? '/Admin/Public/GetImage.ashx?Image=' + shopOwnerImage + '&Width=160&height=100&Format=webP&Quality=90&Crop=5&resolution=50' : ''
}


onMounted(async () => {
	await getCartData()
	const gaOrderlines = orderLines.value.map(ol => getOrderLineGaItem(ol))
	trackToDatalayer({
		event: 'begin_checkout',
		ecommerce: {
			items: gaOrderlines
		}
	});
	if(settings.shopname !== 'møblér'){
		shopInformation.value = settings.shopInfos;
	}
})
</script>

<template>
	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('Cart.UpdatingCart') }}
	</Spinner>
	<div v-else-if="!orderLines || orderLines.length === 0"
		 class="container">
		<div class="flex flex-col gap-y-4 items-center justify-center min-h-[50vh]">
			<h2>{{ getTranslation('Cart.NoLines') }}</h2>
			<RenderAction
				action="link"
				theme="primary"
				size="normal"
				:data="'/'">
				{{ getTranslation('Checkout.GoToShop') }}
				<font-awesome-icon :icon="['fas', 'shop']" />
			</RenderAction>
		</div>
	</div>
	<div v-else>
		<div class="grid xl:grid-cols-4 gap-y-2 md:gap-y-6 xl:gap-y-0 xl:gap-x-8">

			<div class="flex flex-col gap-y-6 xl:col-span-3">
				<div class="flex flex-col gap-y-2 md:gap-y-0 md:flex-row md:justify-between">
					<div class="flex flex-col">
						<h3>{{ getTranslation('CheckoutYourCartHeader') }}</h3>
						<p>{{ getTranslation('CheckoutTotalPrice') }}: <strong>{{ cart.totalInclFees }}</strong></p>
					</div>
					<div class="flex justify-between md:justify-start md:gap-x-2">
						<RenderAction
							type="button"
							theme="white"
							data="/">
							{{ getTranslation('Checkout.ContinueShopping') }}
						</RenderAction>
						<RenderAction
							v-if="settings?.checkoutPage"
							action="link"
							theme="primary"
							:data="settings?.checkoutPage"
						>
							{{ getTranslation('Checkout.ShowCart.NextStep') }}
							<font-awesome-icon :icon="['fas', 'arrow-right']" />
						</RenderAction>
					</div>
				</div>

				<!-- TODO: Notification Element (Tillykke med fri fragt) -->

				<div class="flex flex-col">
					<template v-for="(orderLine, index) in orderLines"
							  :key="orderLine.id"
					>
						<ProductLine
							:product="orderLine"
							@updateOrderLine="orderLineUpdate"
							@deleteOrderLine="orderLineDeletion"
							@deleteVoucher="voucherDeletion"
						/>
					</template>
				</div>
				<div>
					<div class="flex flex-col-reverse lg:flex-row gap-4">
						<div v-if="!hasOrderlineDiscount()"
							class="flex gap-x-2">
							<strong>{{ getTranslation('Checkout.GotVoucher') }}</strong>
							<RenderAction
								type="button"
								theme="link"
								size="none"
								@click="showVoucherModal = true"
							>
								{{ getTranslation('Checkout.ApplyVoucherHere') }}
							</RenderAction>

							<VoucherModal
								:show="showVoucherModal"
								@close="showVoucherModal = false"
							/>
						</div>

						<ul class="lg:basis-1/2 lg:ml-auto list-none flex flex-col">
							<li class="flex justify-between">
								<span>{{ getTranslation('CheckoutTotalNoFees') }}:</span>
								{{ cart.totalNoFees }}
							</li>
							<li class="flex justify-between">
								<span>{{ getTranslation('CheckoutDelivery') }}:</span>
								{{ cart.shippingFee }}
							</li>
							<li class="flex flex-col mt-2 gap-2">
								<div class="flex justify-between">
									<strong class="text-2xl">{{ getTranslation('CheckoutTotalPrice') }}:</strong>
									<strong class="text-2xl">{{ cart.totalInclFees }}</strong>
								</div>
								<div class="italic text-sm text-right">
									<span>{{ getTranslation('CheckoutVatTotal') }}:</span>
									{{  cart.vatTotal }}
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div class="flex flex-col-reverse gap-y-2 md:gap-y-0 md:flex-row md:justify-between">
					<PaymentMethodLogos :all-payments="true" />

					<div class="flex justify-between md:justify-start md:gap-x-2">
						<!-- TODO: Test if this changes to the correct link on the local shops -->
						<RenderAction
							type="button"
							theme="white"
							:data="settings?.mainShopLink">
							{{ getTranslation('Checkout.ContinueShopping') }}
						</RenderAction>

						<RenderAction
							v-if="settings?.checkoutPage"
							action="link"
							theme="primary"
							:data="settings?.checkoutPage">
							{{ getTranslation('Checkout.ShowCart.NextStep') }}
							<font-awesome-icon :icon="['fas', 'arrow-right']" />
						</RenderAction>
					</div>
				</div>
			</div>

			<!-- Aside -->
			<div class="flex flex-col gap-y-4">
				<div
					v-if="shopInformation"
					class="flex flex-col items-start border border-grey rounded p-6 gap-y-4">
					<img
						v-if="shopInformation[0].ShopOwnerImage || shopInformation[0].ShopOwnerImageFull"
						width="160"
						height="110"
						:src="setShopOwnerImage()"
						:alt="shopInformation.ShopName"
						loading="lazy"
					/>
					<div class="flex flex-col justify-content gap-2">
						<div class="font-bold" v-if="shopInformation[0].Phone">Tlf. {{ shopInformation[0].Phone }}</div>
						<div v-if="shopInformation[0]?.ShopInformationList?.length > 0">
							<div v-for="row in shopInformation[0].ShopInformationList"
								class="flex gap-x-2">
								<div v-if="row.columnOne"
									 :class="row.columnTwo ? 'basis-1/2' : ''">
									{{ row.columnOne }}
								</div>
								<div v-if="row.columnTwo">{{ row.columnTwo }}</div>
							</div>
						</div>
						<div v-if="shopInformation[0].ShopInformationText"
							 v-html="shopInformation[0].ShopInformationText">
						</div>
						<!-- TODO: Remove fallback, when all information has been changed -->
						<template v-else-if="shopInformation.OpeningHours">
							<div v-html="shopInformation.OpeningHours"></div>
						</template>
					</div>
				</div>
				<FAQ
					v-if="usps.length > 0"
					:data="usps"
					type="trust"
				>
				</FAQ>
				<FAQ
					v-if="faqs.length > 0"
					:title="getTranslation('FAQ.Title')"
					:data="faqs"
					type="list"
				/>
			</div>
		</div>
		<Recommendations
			type="purchasedwith"
			:product-id="orderLines[0].productId"
			:variant-id="orderLines[0].variantId"
			:take="8"
			:contain="false"
		/>
	</div>
</template>
