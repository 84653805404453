<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { getSettings, getTranslation } from '@/ts/utilities.ts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const ShowModal = defineAsyncComponent(() => import('@/components/ShowModal.vue'))

const settings = getSettings()

</script>

<template>
	<div v-if="settings?.shopInfos[0]?.IsLocalWebshop"
		 class="flex items-center justify-end gap-x-4 md:gap-x-6 lg:gap-x-4 xl:gap-x-6 w-full">
		<a
			v-if="settings?.shopInfos[0]?.Phone"
			:href="'tel:' + settings?.shopInfos[0]?.Phone"
			class="flex items-center flex-col md:flex-row md:gap-2 underline text-black hover:text-black/75 hover:no-underline"
		>
			<font-awesome-icon :icon="['fal', 'phone']" />
			<small class="text-xs mt-1 md:text-sm md:mt-0 md:text-center">
				{{ settings?.shopInfos[0]?.Phone }}
			</small>
		</a>
		<ShowModal
			:modal-title="getTranslation('openinghours')"
			:modal-content="settings?.shopInfos[0]?.OpeningHours">
			<small>{{ getTranslation('openinghours') }}</small>
		</ShowModal>
	</div>
</template>
