<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'
import {getTranslation} from '@/ts/utilities.ts'
const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))

interface Props {
	filters: []
}

const props = defineProps<Props>()


const selectedFilter = ref('')
const blogData = ref('')
const isLoading = ref(false)
const setFilter = (filter: string) =>{
	selectedFilter.value = filter
	getData()
}

const getData = async() =>{
	isLoading.value = true
	const request = await fetch(`/mobler-api/v2/content/blogs?filter=${selectedFilter.value}`)
	blogData.value = await request.json()
	isLoading.value = false
}

onMounted(async () =>{
	await getData()
})

</script>

<template>
	<div class="container mx-auto px-4 py-8">
		<div class="flex justify-center flex-wrap">
			<div class="category-filters py-2 px-5 rounded pointer mx-2 mb-3"
				 :class="{selected: selectedFilter === ''}"
				 v-on:click="setFilter('')">{{ getTranslation('AllCategories') }}</div>
			<div v-for="filter in filters" :key="filter.$id"
				 class="category-filters py-2 px-5 rounded pointer mx-2 mb-3"
				 :class="{selected: selectedFilter === filter}"
				 v-on:click="setFilter(filter)">{{filter}}</div>
		</div>
	</div>
	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('FilteringBlogs') }}
	</Spinner>
	<div v-else class="container mx-auto px-4 py-8" v-if="blogData.length">
		<div class="grid grid-cols-1 gap-8 mb-8" v-if="blogData.length > 2">
			<div class="w-full" v-for="(featureBlog, index) in blogData.slice(0, 2)" :key="featureBlog.$id">
				<div class="flex flex-col lg:flex-row" :class="{'justify-end': index % 2 !== 0}">
					<div class="flex w-full lg:w-1/2 px-0 px-md-3" >
						<div class="bg-cover bg-center" :style="`background-image:url('/Admin/Public/GetImage.ashx?Image=${featureBlog.Image}&Format=webP&Quality=90&Height=400')`">
							<img class="img-fluid" :src="`/Admin/Public/GetImage.ashx?Image=${featureBlog.Image}&Format=webP&Quality=90&Height=400`" :alt="featureBlog.Header" width="1020" height="400" loading="lazy" />
						</div>
					</div>
					<div class="flex items-center w-full lg:w-1/2 mb-5 lg:mb-0" :class="{'left-0': index % 2 !== 0}">
						<div class=" bg-white p-4">
							<div>
								<h4>{{featureBlog.Header}}</h4>
								<span v-html="featureBlog.Teaser"></span>
							</div>
							<a class="font-bold" :href="featureBlog.Link">{{getTranslation('BlogReadMore')}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="grid md:grid-cols-3 gap-8 latest-blogs">
			<div class="w-full px-0 px-md-3 mb-4" v-for="latestBlog in blogData.slice(blogData.length > 2 ? 2 : 0)" :key="latestBlog.$id">
				<a :href="latestBlog.Link" class="blog block">
					<div class="image" :style="`background-image:url('/Admin/Public/GetImage.ashx?Image=${latestBlog.Image}&Width=400&height=200&Format=webP&Quality=90')`">
						<img class="hidden" width="400" height="200" :src="`/Admin/Public/GetImage.ashx?Image=${latestBlog.Image}&Width=400&height=200&Format=webP&Quality=90`" alt="" loading="lazy" />
					</div>
					<div class="flex relative h-[150px] bottom-[25px] w-3/4 m-auto bg-white">
						<div class="absolute w-full py-2 bottom-0">
							<h4 class=" px-1 pt-3 mb-2 bg-white font-bold text-center mx-auto xl:text-3xl lg:text-2xl md:text-xl sm:text-2xl">{{latestBlog.Header}}</h4>
							<p class="text-center font-bold m-0 text-primary">{{getTranslation('BlogReadMore')}}</p>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</template>
