<script setup lang="ts">
import {defineAsyncComponent, ref} from "vue";
import { getTranslation } from "@/ts/utilities.ts";
import { subscribeCustomerToNewsletter } from "@/ts/Helpers/newsletter.ts";
const RenderAction = defineAsyncComponent(() => import("@/components/helpers/RenderAction.vue"))

const name = ref('')
const email = ref('')
const terms = ref(false)
const signupLocation = window.location.href
const formDataString = ref('')
const success = ref(false)

function subscribe() {
	// Split name after first space to get first and last name
	let firstName;
	let lastName;
	if (name) {
		[firstName, lastName] = name.value.split(' ');
	}

	const formData = new FormData();
	// Map data to corresponding merge tags
	const data = {
		'EMAIL': email.value,
		'FNAME': firstName,
		'LNAME': lastName,
		'SIGNUP': signupLocation,
		'GDPR': 'Accepteret'
	};
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			formData.append(key, data[key]);
		}
	}

	// Ignored, because TS doesn't accept formData as a string, because it can have a File object, but works as it should
	// More info: https://github.com/Microsoft/TypeScript/issues/30584
	// @ts-ignore
	formDataString.value = new URLSearchParams(formData).toString();

	subscribeCustomerToNewsletter(formDataString.value)
		.then(() => {
			success.value = true;
		})
}
</script>

<template>
	<form class="w-full" v-if="!success">
		<div class="js-form flex flex-col gap-y-2">
			<div class="flex gap-2">
				<div class="flex flex-col flex-wrap gap-2">
					<label>
						<span class="text-sm">{{ getTranslation('Newsletter.Navn') }}</span>
						<input class="form-input rounded-sm border border-beige mt-1 w-full" type="text" name="FNAME" id="newsletterSignupName" v-model="name" required />
					</label>
				</div>
				<div class="flex flex-col flex-wrap gap-2">
					<label>
						<span class="text-sm">{{ getTranslation('Newsletter.Email') }}</span>
						<input class="form-input rounded-sm border border-beige mt-1 w-full" type="email" name="EMAIL" id="newsletterSignupEmail" v-model="email" required />
					</label>
				</div>
			</div>
			<div>
				<label class="inline-flex items-baseline gap-x-2">
					<input type="checkbox" id="newsletterCheckbox" name="newsletter" v-model="terms" />
					<span v-html="getTranslation('Newsletter.TermsOfUseLabel')"></span>
				</label>
			</div>
			<div class="form-element">
				<RenderAction
					action="button"
					theme="dark"
					:disabled="!terms"
					@click.prevent="subscribe()">
					{{ getTranslation('Newsletter.Subscribe') }}
				</RenderAction>
			</div>
		</div>
	</form>
	<div v-if="success">
		{{ getTranslation('Newsletter.Success') }}
	</div>
</template>
