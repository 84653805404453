<script setup lang="ts">
import { defineAsyncComponent }	from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))

interface Props {
	usp?: object,
	context: string,
	layout: string,
	index?: number,
	productCount?: number
}

const props = defineProps<Props>()


const setPositionInGrid = () => {
	if (props.context != 'list') return
	if (props.index == 0) {
		return 'col-span-1'
	} else if (props.index == 1) {
		return 'col-span-1 md:col-span-2'
	} else if (props.index == 2) {
		return 'col-span-1 md:col-span-2'
	} else {
		return 'hidden'
	}
}

</script>

<template>
	<div v-if="props.usp" class="rounded border border-grey-light overflow-hidden" :class="setPositionInGrid()">
		<div v-if="props.usp?.image"
			 class="bg-white-warm">
			<img
				:src="'/Admin/Public/GetImage.ashx?Image=' + props.usp?.image + '&Width=600&height=375&Format=webP&Quality=90&Crop=5&resolution=50'"
				:alt="props.usp?.name"
				width="600"
				height="375"
				class="object-cover object-center h-auto aspect-[16/10]"
				loading="lazy"
			>
		</div>
		<div class="flex flex-col gap-y-6 p-8 h-full"
			:class="props.context == 'list' ? 'bg-white' : 'bg-beige-light'">
			<div v-if="props.usp?.header"
				class="flex items-center gap-2"
			>
				<font-awesome-icon
					v-if="props.usp?.icon"
					:icon="['fal', props.usp?.icon]"
					class="text-2xl"
				/>
				<h3 class="text-pretty">{{ props.usp?.header }}</h3>
			</div>
			<template v-if="props.usp?.type === 'BULLETS' && props.usp?.bulletUsps">
				<ul class="list-none flex flex-col gap-y-4">
					<li class="flex gap-x-2 items-baseline"
						v-for="(bullet, index) in props.usp?.bulletUsps" :key="index">
						<font-awesome-icon
							v-if="bullet.icon"
							:icon="['fal', bullet.icon]"
						/>
						<p class="flex flex-col">
							<strong>{{ bullet.header }}</strong>
							{{ bullet.teaser }}
						</p>
					</li>
				</ul>
			</template>
			<p v-else>{{ props.usp?.teaser }}</p>
			<RenderAction
				v-if="props.usp?.buttonText && props.usp?.buttonLink"
				action="link"
				theme="link"
				target="external"
				size="none"
				:data="props.usp?.buttonLink"
			>
				{{ props.usp?.buttonText }}
			</RenderAction>
		</div>
	</div>
</template>
