<script setup lang="ts">
import {defineAsyncComponent, ref} from 'vue'
import {getTranslation} from '../ts/utilities.ts'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
const Modal = defineAsyncComponent(() => import('@/components/helpers/Modal.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))

const props = defineProps(['show', 'facets'])
const emit = defineEmits(['close', 'updateFilter', 'resetFilters'])

const selectedFilter = ref(null)
const selectedOptionsModel = ref([])

const selectOption = (event, option) =>{
	if(event.target.checked){
		selectedOptionsModel.value.push(option)
	} else{
		selectedOptionsModel.value = selectedOptionsModel.value.filter( (o) => {return o !== option})
	}
}

const isOptionSelected = (option) =>{
	return selectedOptionsModel.value.includes(option)

}

const resetView = () =>{
	if(selectedFilter.value){
		resetCurrentFilterOptions()
	} else{
		emit('resetFilters')
		emit('close')
	}
}
const resetCurrentFilterOptions = () =>{
	selectedOptionsModel.value = []
}

const resetFilter = () =>{
	selectedFilter.value = null
}

const updateProductList = () =>{
	emit('updateFilter', 'facets', selectedOptionsModel.value, selectedFilter.value.parametername)
}

const updateProductListAndClose = () =>{
	updateProductList()
	resetFilter()
	resetCurrentFilterOptions()
	emit('close')
}

const navigateForward = (facet) =>{
	selectedFilter.value = facet
	selectedOptionsModel.value = [...selectedFilter.value.facetOptions.filter(fo => fo.selected)]
}

const navigateBack = () =>{
	updateProductList()
	resetFilter()
	resetCurrentFilterOptions()
}

</script>

<template>
	<Modal class="overflow-auto"
		   :show="props.show"
		   type="full"
		   @close="emit('close')"
		>
		<template #modal-title>
			<div class="flex justify-between">
				<span class="cursor-pointer font-normal flex items-center gap-2" v-if="selectedFilter" v-on:click="navigateBack"><font-awesome-icon :icon="['fas', 'arrow-left']"/> Tilbage</span>
				<span v-if="!selectedFilter">{{ getTranslation('Products.Filters.Header') }}</span>
			</div>
		</template>
		<template #content>
			<div class="d-flex w-full" v-if="selectedFilter">
				<h4 class="mb-2">{{selectedFilter.name}}</h4>
				<ul class="list-none border-t border-grey-light">
					<li class="flex justify-between items-center border-b border-grey-light p-2 " v-for="option in selectedFilter.facetOptions" :key="option.$id">
						<label class="flex items-center gap-2 cursor-pointer w-full">
							<input type="checkbox" :checked="isOptionSelected(option)" v-on:change="selectOption($event, option)">
							{{option.name}}
						</label>

						<span>({{option.productsCount}})</span>
					</li>
				</ul>
			</div>
			<div class="d-flex w-full" v-else>
				<ul class="list-none border-t border-grey-light">
					<li class="flex justify-between items-center gap-2 border-b border-grey-light p-2 cursor-pointer" v-for="facet in props.facets" v-on:click="navigateForward(facet)" :key="facet.$id">
						<div class="basis-1/2">{{ facet.name }}</div>
						<small class="ml-auto grow-0 truncate">{{ facet.facetOptions.filter(fo => fo.selected).map(fo => fo.name).join(', ') }}</small>
						<font-awesome-icon :icon="['fas', 'arrow-right']"/>
					</li>
				</ul>
			</div>
		</template>
		<template #footer>
			<RenderAction
				action="button"
				theme="primary"
				class="col-span-2"
				@click="updateProductListAndClose">
				{{ getTranslation('Products.Filters.Select') }}
			</RenderAction>
			<RenderAction
				action="button"
				theme="secondary"
				@click="resetView">
				{{ getTranslation('Products.Filters.Reset') }}
			</RenderAction>
		</template>
	</Modal>
</template>
