<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue'
const ButtonTag = defineAsyncComponent(() => import('@/components/ui/actions/ButtonTag.vue'))
const LinkTag = defineAsyncComponent(() => import('@/components/ui/actions/LinkTag.vue'))

const props = defineProps({
	data: {
		type: String,
	},
	action: {
		type: String,
		default: 'link',
		validator: (value) => {
			return [
				'link',
				'button'
			].includes(value)
		}
	},
	target: {
		type: String,
		default: 'internal',
		validator: (value) => {
			return [
				'internal',
				'external'
			].includes(value)
		}
	},
	theme: {
		type: String,
		default: 'primary',
		validator: (value) => {
			return [
				'primary',
				'secondary',
				'outline',
				'faded',
				'dark',
				'white',
				'link',
				'none'
			].includes(value)
		}
	},
	size: {
		type: String,
		default: 'normal',
		validator: (value) => {
			return [
				'sm',
				'normal',
				'lg',
				'none'
			].includes(value)
		}
	},
	disabled: {
		type: Boolean,
		default: false
	}
})

function component() {
	let component

	if (props.action === 'link') {
		component = LinkTag
	}

	if(props.action === 'button') {
		component = ButtonTag
	}

	return component
}

const theme = computed(() => {
	if (props.theme === 'primary') return 'rounded-full bg-primary text-white hover:bg-primary-dark active:border-primary-darker'
	if (props.theme === 'secondary') return 'rounded-full bg-white border border-white text-black'
	if (props.theme === 'outline') return 'rounded-full bg-white border-2 border-primary text-primary outline-none hover:border-primary-dark hover:text-primary-dark active:border-primary active:bg-primary focus:ring-2 focus:ring-offset-2'
	if (props.theme === 'dark') return 'rounded-full bg-black text-white hover:bg-black/90'
	if (props.theme === 'transparent') return 'rounded-full border-2 border-primary text-primary outline-none hover:border-primary-dark hover:text-primary-dark active:border-primary active:bg-primary focus:ring-2 focus:ring-offset-2'
	if (props.theme === 'white') return 'rounded-full bg-white text-black border-2 border-black hover:text-black hover:border-grey-dark active:border-grey-dark active:bg-grey'
	if (props.theme === 'faded') return 'rounded-full bg-grey-light text-black border-2 border-transparent hover:text-black hover:border-grey-dark active:border-grey-dark active:bg-grey'
	if (props.theme === 'link') return 'underline text-left text-current hover:text-current'
	if (props.theme === 'none') return 'text-left'
	return ''
})

const size = computed(() => {
	if (props.size === 'sm') return 'px-4 py-1 text-sm'
	if (props.size === 'normal') return 'px-6 py-2'
	if (props.size === 'lg') return 'px-8 py-3'
	if (props.size === 'none' || null ) return 'h-fit'
	return ''
})

let linkTarget: string = ''

const target = computed(() => {
	if (props.action === 'link') {
		linkTarget = props.target === 'external' ? '_blank' : '_self'
	}

	return linkTarget
})

// actionClicked() {
// 	this.$emit("action-clicked");
// }

</script>

<template>
	<component
		:is="component()"
		:data="data"
		class="flex items-center gap-2 cursor-pointer"
		:class="[theme, size, (props.theme != 'link' && props.theme != 'none') ? 'justify-center font-bold disabled:opacity-25 cursor-none' : 'px-0']"
		:target="target"
		:disabled="props.disabled"
		>
		<slot></slot>
	</component>
</template>
