<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref, watch} from "vue"
import {getSettings, getTranslation, getNavigation, setLogo} from "@/ts/utilities.ts"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const MegaNav = defineAsyncComponent(() => import('@/components/MegaNav.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))

const props = defineProps({
	toggleNavigation: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits(['closeMobileMenu'])

const settings = getSettings()
const mobileNavChildrenElement = ref(null)
const desktopNavigationItems = ref([])
const mobileNavigationItems = ref([])
const secondaryMobileNavigationItems = ref([])

const visible = ref(false)
const showMegaNav = ref(false)
const megaNav = ref({})

const navigation = getNavigation()

const setDesktopNavigation = () => {
	desktopNavigationItems.value = navigation.rootNode.children.filter((navItem) => !navItem.menuHideOnDesktop)
}

const setMobileNavigation = () => {
	mobileNavigationItems.value = navigation.rootNode.children.filter((navItem) => navItem.nodeType == 'PAGE' && !navItem.menuHideOnMobile && !navItem.menuSecondaryItem)
}

const setSecondaryMobileNavigation = () => {
	secondaryMobileNavigationItems.value = navigation.rootNode.children.filter((navItem) => navItem.nodeType == 'PAGE' && !navItem.menuHideOnMobile && !navItem.menuCustomStyling && navItem.menuSecondaryItem)
}

const toggleMegaNav = (navItem) => {
	if (megaNav.value.id !== navItem.id) {
		megaNav.value = navItem
		showMegaNav.value = true
	} else {
		megaNav.value = {}
		showMegaNav.value = false
	}
}

const closeMegaNav = () => {
	megaNav.value = {}
	showMegaNav.value = false
}

const closeMobileMenu = () => {
	visible.value = false
	emit('closeMobileMenu')
}

const activeParentId = ref<number | null>(null)
const activeChildId = ref<number | null>(null)

const toggleChildItems = (parentId: number, childId: number | null = null) => {
	if (childId === null) {
		activeParentId.value = activeParentId.value === parentId ? null : parentId
		activeChildId.value = null
	} else {
		activeChildId.value = activeChildId.value === childId ? null : childId
	}

	// Scroll to the top of the mobileNavChildrenElement
	if (mobileNavChildrenElement.value) {
		mobileNavChildrenElement?.value.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
	}
}

const navigateBackInMobileNavigation = () => {
	if (activeChildId.value) {
		activeChildId.value = null
	} else {
		activeParentId.value = null
	}
}

onMounted (async () => {
	if (props.toggleNavigation) {
		visible.value = true
	}

	getNavigation()
	setDesktopNavigation()
	setMobileNavigation()
	setSecondaryMobileNavigation()
})
watch(showMegaNav, (value) => {
	if (value) {
		document.documentElement.classList.add('h-dvh', 'menu-open')
	} else {
		document.documentElement.classList.remove('h-dvh', 'menu-open')
	}
})
</script>

<template>
	<div>
		<div class="max-lg:absolute max-lg:top-0 max-lg:left-0 max-lg:w-full max-lg:bg-white max-lg:h-dvh max-lg:overflow-y-scroll max-lg:z-10 max-lg:visible"
			:class="{ 'max-lg:invisible': !props.toggleNavigation }">

			<div class="flex lg:hidden justify-between p-4 border-b border-b-grey">
				<img
					width="400"
					height="46"
					class="max-h-[40px] w-full object-contain object-left hidden md:inline-block"
					:src="setLogo().logoPath"
					:alt="getTranslation('LogoAltText')"
					loading="lazy" />
				<img
					width="400"
					 height="46"
					 class="max-h-[40px] w-full object-contain object-left inline md:hidden"
					:src="setLogo().mobileLogoPath"
					:alt="getTranslation('LogoAltText')"
					loading="lazy" />

				<button
					type="button"
					class="flex items-center justify-self-end ml-auto px-3 text-base hover:underline"
					@click="closeMobileMenu"
				>
					<span class="mr-2 inline-flex">
						{{ getTranslation('Close') }}
					</span>
					<font-awesome-icon :icon="['fal', 'close']" />
				</button>
			</div>

			<nav class="max-lg:hidden h-full flex flex-col gap-y-8">
				<ul class="items-center flex-wrap gap-y-6 flex gap-6 list-none">
					<li v-for="navItem in desktopNavigationItems" :key="navItem.id" class="text-xl font-semibold last-of-type:ml-auto">
						<RenderAction
							v-if="navItem.hasChildren"
							type="button"
							theme="none"
							size="none"
							class="flex hover:underline"
							@click="toggleMegaNav(navItem)"
							:class="{ 'underline': megaNav == navItem }">
							{{ navItem.name }}
						</RenderAction>

						<RenderAction
							v-else-if="navItem.menuCustomStyling"
							theme="none"
							class="rounded-full mx-4"
							:data="navItem.link"
							:style="{ 'color': navItem.menuTextColor, 'background-color': navItem.menuBackgroundColor }"
						>
							{{ navItem.name }}
						</RenderAction>

						<RenderAction
							v-else
							type="link"
							theme="none"
							size="none"
							class="flex hover:underline"
							:data="navItem.link">
							{{ navItem.name }}
						</RenderAction>
					</li>
				</ul>
			</nav>

			<nav class="h-full max-lg:flex hidden flex-col gap-y-8">
				<button
					class="flex items-center gap-2 p-4 border-b border-b-grey bg-white w-full"
					@click="navigateBackInMobileNavigation()"
					:class="{ hidden: !activeParentId }">
					<font-awesome-icon :icon="['fal', 'arrow-left']" />
					{{ getTranslation('Back') }}
				</button>
				<ul
					ref="mobileNavChildrenElement"
					v-if="mobileNavigationItems"
					class="relative flex flex-wrap gap-y-6 pt-4 h-full list-none"
				>

					<li v-for="level1Item in mobileNavigationItems" :key="level1Item.id"
						class="font-semibold text-sm w-1/2">
						<RenderAction
							v-if="level1Item.hasChildren"
							type="button"
							theme="none"
							size="none"
							class="px-4 flex flex-col !items-start gap-2"
							:href="level1Item.link"
							@click.prevent="toggleChildItems(level1Item.id)">

							<img class="object-cover object-center w-full aspect-[4/3] rounded"
								 v-if="level1Item.menuImage"
								 width="175"
								 height="100"
								 :src="'/Admin/Public/GetImage.ashx?Image=' + level1Item.menuImage + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
								 :alt="level1Item.name"
								 loading="lazy"
							>
							<span class="flex flex-col gap-1 text-sm">
								<strong>{{ level1Item.name }}</strong>
								<span v-if="level1Item.menuExtraText">{{ level1Item.menuExtraText }}</span>
							</span>
						</RenderAction>

						<RenderAction
							v-else-if="level1Item.menuCustomStyling"
							theme="none"
							class="rounded mx-4 flex justify-center aspect-[4/3]"
							:data="level1Item.link"
							:style="{ 'color': level1Item.menuTextColor, 'background-color': level1Item.menuBackgroundColor }">
							{{ level1Item.name }}
						</RenderAction>

            <RenderAction
              v-else
              type="link"
              theme="none"
              size="none"
              class="px-4 flex flex-col !items-start gap-2"
              :data="level1Item.link"
              >
              {{ level1Item.name }}
            </RenderAction>

						<ul v-if="level1Item.hasChildren && level1Item.children"
							class="list-none absolute top-0 left-0 w-full h-dvh divide-y divide-grey bg-white pb-8 pointer-events-auto"
							:class="{ hidden: activeParentId !== level1Item.id }"
						>
							<li
								v-for="level2Item in level1Item.children"
								:key="level2Item.id"
								class="font-semibold text-sm bg-white"
							>
								<!-- TODO: Use RenderAction -->
								<a
									v-if="level2Item.hasChildren"
									class="p-4 flex items-center gap-2"
									:href="level2Item.link"
									@click.prevent="toggleChildItems(level1Item.id, level2Item.id)">

									<img v-if="level2Item.image1"
										 class="object-cover object-center aspect-[4/3] w-1/3 basis-1/3 rounded"
										 :src="'/Admin/Public/GetImage.ashx?Image=' + level2Item.image1 + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
										 :alt="level2Item.name"
										loading="lazy">
									<span class="text-base font-bold basis-1/2">{{ level2Item.name }}</span>
									<font-awesome-icon class="ml-auto text-xl" :icon="['fal', 'arrow-right']" />
								</a>

								<a
									v-else
									class="p-4 flex items-center gap-2"
									:href="level2Item.link"
								>
									<img v-if="level2Item.image1"
										 class="object-cover object-center aspect-[4/3] w-1/3 basis-1/3 rounded"
										 :src="'/Admin/Public/GetImage.ashx?Image=' + level2Item.image1 + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
										 :alt="level2Item.name"
										loading="lazy">
									<span class="text-base font-bold basis-1/2">{{ level2Item.name }}</span>
								</a>

								<ul
									v-if="level2Item.hasChildren && level2Item.children"
									class="list-none absolute top-0 left-0 w-full h-dvh divide-y divide-grey bg-white pb-8"
									:class="{ hidden: activeChildId !== level2Item.id }"
								>
									<li
										v-for="level3Item in level2Item.children"
										class="font-semibold text-sm bg-white"
									>
										<a
											class="p-4 flex items-center gap-2"
											:href="level3Item.link">

											<img v-if="level3Item.image1"
												 class="object-cover object-center aspect-[4/3] w-1/3 basis-1/3 rounded"
												 :src="'/Admin/Public/GetImage.ashx?Image=' + level3Item.image1 + '&Width=175&height=100&Format=webP&Quality=90&Crop=5&resolution=100'"
												 :alt="level3Item.name"
											loading="lazy">
											<span class="text-base font-bold basis-1/2">{{ level3Item.name }}</span>
											<font-awesome-icon class="ml-auto text-xl" :icon="['fal', 'arrow-right']" />
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>

				<ul class="list-none border-t border-t-grey">
					<li v-for="navItem in secondaryMobileNavigationItems"
						class="w-full font-semibold text-sm"
						:key="navItem.id">
						<RenderAction
							action="link"
							theme="none"
							size="none"
							class="inline-flex items-center justify-between w-full px-4 py-4 border-b border-b-grey hover:underline"
							:data="navItem.link"
						>
							{{ navItem.name }}
							<font-awesome-icon class="text-xl" :icon="['fal', 'arrow-right']" />
						</RenderAction>
					</li>
				</ul>

				<!-- Mobile Footer -->
				<div class="lg:hidden pt-8 pb-16 px-4 flex flex-col gap-y-2 bg-beige-light">
					<span class="text-sm font-bold">{{ settings?.shopInfos[0]?.ShopName }}</span>
					<div class="flex *:basis-1/2">
						<ul class="list-none text-sm">
							<li v-if="settings?.shopInfos[0]?.Address">{{ settings?.shopInfos[0]?.Address }}</li>
							<li v-if="settings?.shopInfos[0]?.PostalAndCity">{{ settings?.shopInfos[0]?.PostalAndCity }}</li>
							<li v-if="settings?.localShopPage"><a class="underline" :href="settings?.localShopPage">{{ getTranslation('ShowOnMap') }}</a></li>
							<li>&nbsp;</li>
							<li v-if="settings?.findDealerLink"><a class="underline" :href="settings?.findDealerLink">{{ getTranslation('HeaderNav.FindShop') }}</a></li>
						</ul>

						<ul v-if="settings?.shopInfos[0]?.Phone || settings?.shopInfos[0]?.Email" class="list-none text-sm">
							<li v-if="settings?.shopInfos[0]?.Phone">{{ getTranslation('Shops.Phone') }} <a class="underline" :href="'tel:' + settings?.shopInfos[0]?.Phone">{{ settings?.shopInfos[0]?.Phone }}</a></li>
							<li v-if="settings?.shopInfos[0]?.Email">{{ getTranslation('Shops.Email') }} <a class="underline" :href="'mailto:' + settings?.shopInfos[0]?.Email">{{ settings?.shopInfos[0]?.Email }}</a></li>
						</ul>
					</div>

				</div>
			</nav>
			<MegaNav
				v-if="showMegaNav && megaNav"
				:navItems="megaNav"
				@close="closeMegaNav"
			/>
		</div>
	</div>
</template>
