<script setup lang="ts">
import {computed, defineAsyncComponent, onMounted, ref, watch} from 'vue'
import {getTranslation} from '@/ts/utilities.ts'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
interface Props {
	currentPage: number,
	totalPages: number,
	pageSize: number,
	maxPagesShown: number
}

const props = defineProps<Props>()
const emit = defineEmits(['onPaging'])
const currentPageNumber = ref(0)

const startPage = ref(1)
const endPage = ref(1)

const navigationPagination = (pageNum: number, direction: string = '') =>{
	if(direction === ''){
		currentPageNumber.value = pageNum
	} else{
		switch (direction) {
		case 'prev':
			currentPageNumber.value--
			break
		case 'next':
			currentPageNumber.value++
			break
		}
	}
	emit('onPaging', currentPageNumber.value)
}

const getPagination = computed(() =>{
	if(props.totalPages <= props.maxPagesShown){
		startPage.value = 1
		endPage.value = props.totalPages
	} else{
		let maxPagesShownBeforeCurrentPage = Math.floor(props.maxPagesShown / 2)
		let maxPagesShownAfterCurrentPage = Math.ceil(props.maxPagesShown / 2) - 1

		if (currentPageNumber.value <= maxPagesShownBeforeCurrentPage) {
			startPage.value = 1
			endPage.value = props.maxPagesShown
		} else if (
			currentPageNumber.value + maxPagesShownAfterCurrentPage >=
			props.totalPages
		) {
			// current page is at the end of the pagination
			startPage.value = props.totalPages - props.maxPagesShown + 1
			endPage.value = props.totalPages
		} else {
			// current page is somewhere in the middle of the pagination
			startPage.value = currentPageNumber.value - maxPagesShownBeforeCurrentPage
			endPage.value = currentPageNumber.value + maxPagesShownAfterCurrentPage
		}
	}
	return Array.from(Array(endPage.value + 1 - startPage.value).keys()).map(
		(i) => startPage.value + i
	)
})

const startBreakPoints = computed(() => {
	return !getPagination.value.includes(1)
})

const endBreakPoints = computed(() => {
	return !getPagination.value.includes(props.totalPages)
})

const url = ref('')

onMounted(() =>{
	url.value = document.location.pathname
})

watch( () => props.currentPage, (newValue) =>{
	currentPageNumber.value = newValue
},{
	deep:true,
	immediate: true
})

</script>

<template>
	<div class="container mb-4" v-if="props.totalPages > 1">
		<nav class="isolate flex flex-col lg:flex-row justify-center gap-y-4 lg:gap-2">
			<RenderAction
				v-if="currentPageNumber > 1"
				action="link"
				theme="outline"
				size="sm"
				:data="url + `?PageNum=${currentPageNumber - 1}`"
				@click.prevent="navigationPagination(-1, 'prev')">
				<font-awesome-icon :icon="['fas', 'arrow-left']" />
				{{ getTranslation('Pagination.Previous') }}
			</RenderAction>
			<RenderAction
				v-if="currentPageNumber < props.totalPages"
				class="lg:order-3"
				action="link"
				theme="primary"
				size="sm"
				:data="url + `?PageNum=${currentPageNumber + 1}`"
				@click.prevent="navigationPagination(-1, 'next')">
				{{ getTranslation('Pagination.Next') }}
				<font-awesome-icon :icon="['fas', 'arrow-right']" />
			</RenderAction>
			<div class="flex flex-wrap gap-2 lg:order-2 *:relative *:inline-flex *:items-center *:px-4 *:py-2 *:text-sm *:rounded-full">
				<RenderAction
					v-if="startBreakPoints"
					:class="currentPageNumber === 1 ? 'bg-white pointer-events-none' : 'bg-white hover:bg-white-hover hover:text-black text-black cursor-pointer'"
					action="link"
					size="none"
					theme="none"
					:data="url + '?PageNum=1'"
					@click.prevent="navigationPagination(1)">
					1
				</RenderAction>
				<div v-if="startBreakPoints" class="pointer-events-none">
					...
				</div>
				<RenderAction
					v-for="page in getPagination" :key="page"
					class="focus:ring-2 focus:ring-offset-2"
					:class="currentPageNumber === page ? 'bg-primary text-white pointer-events-none' : 'bg-white text-black hover:bg-white-hover hover:text-black cursor-pointer'"
					action="link"
					size="none"
					theme="none"
					:data="url + `?PageNum=${page}`"
					@click.prevent="navigationPagination(page)">
					{{ page }}
				</RenderAction>
				<div v-if="endBreakPoints" class="pointer-events-none">
					...
				</div>
				<RenderAction
					v-if="endBreakPoints"
					:class="currentPageNumber === props.totalPages ? 'bg-white pointer-events-none' : 'bg-white text-black hover:bg-white-hover hover:text-black cursor-pointer'"
					action="link"
					size="none"
					theme="none"
					:data="url + `?PageNum=${props.totalPages}`"
					@click.prevent="navigationPagination(props.totalPages)">
					{{ props.totalPages }}
				</RenderAction>
			</div>
		</nav>
	</div>
</template>
