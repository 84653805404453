<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'
import {MapsData, MarkerData} from '@/interfaces/MapsViewInterface.ts'
import mapMarkerImgUrl from '../assets/MapMarker.png'
import {getTranslation, setShop} from '@/ts/utilities.ts'

const DealerCard = defineAsyncComponent(() => import('@/components/DealerCard.vue'))

interface Props {
	mapsData: MapsData
}

declare global {
	interface Window {
		initMap: () => void
	}
}

declare let Cookiebot: any

const props = defineProps<Props>()

const mapLoaded = ref(false)

const mapsData: MapsData = {
	apiKey: props.mapsData.apiKey,
	endpoint: '/mobler-api/v2/shops/getshops',
}

const mapContainer = ref(null)
let map = null
let dealers: MarkerData[] = ref([])
let infoWindows: object[] = ref([])

const viewShopText = getTranslation('FindShopCTA')
const openingHourstext = getTranslation('FindShopOpeningHoursHeader')

const initMap = async () => {
	const { Map, InfoWindow } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
	const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary

	const markersData: MarkerData[] = dealers.value
	let zoom = 15
	if (markersData.length > 3) {
		zoom = 7
	}
	const center: google.maps.LatLngLiteral = {lat: 55.977675, lng: 10.933507}

	map = new Map(mapContainer.value, {
		center,
		zoom,
		disableDefaultUI: true,
		mapId: 'FindShop'
	})

	markersData.forEach((dealer: MarkerData) => {

		let contentString = `
				<div class="flex flex-col gap-y-4 p-2 min-w-60 text-sm">
					<div class="p-4 absolute -right-6 -top-6 bg-white rounded-full shadow cursor-pointer w-12 h-12 flex justify-center items-center">
						<i class="fal fa-times text-black text-lg"></i>
					</div>
					<address class="not-italic">
						<p class="text-lg">${dealer.ShopName}</p>
						${ dealer.Address }<br>
						${ dealer.PostalAndCity }<br>
						${ dealer.Phone }<br>
						<a href="mailto:${dealer.Email}" class="text-black underline">
							${ dealer.Email }
						</a>
					</address>

					${dealer.ShopInformationList?.length > 0 ? `
						<div>
							${dealer.ShopInformationList.map(row => `
								 <div class="flex gap-x-2">
									${row.columnOne ? `<div class="${row.columnTwo ? 'basis-1/2' : ''}">${row.columnOne}</div>` : ''}
									${row.columnTwo ? `<div>${row.columnTwo}</div>` : ''}
								</div>
							`).join('')}
						</div>
					` : '' }

					${dealer.ShopInformationText ? `
						<div v-html="${dealer?.ShopInformationText}"></div>
					` : ''}

					<strong>${openingHourstext}</strong>

					<div class="max-w-[10rem]">${dealer.OpeningHours}</div>
					<div class="flex">
						<a href="${dealer.Link}" class="cursor-pointer rounded-full hover:no-underline focus:no-underline rounded-full bg-primary text-white hover:bg-primary-dark active:border-primary-darker px-6 py-2 flex items-center justify-center gap-2 font-bold disabled:opacity-25 js-shop-link" data-dealer="${dealer.MenuText}">
							${viewShopText}
						</a>
					</div>
				</div>
				`

		let infowindow = new InfoWindow({
			content: contentString
		})

		infoWindows.value.push(infowindow)

		let latLng = {lat: dealer.Lat, lng: dealer.Lng}
		const image = document.createElement('img')
		image.src = mapMarkerImgUrl


		let marker = new AdvancedMarkerElement({
			map,
			position: latLng,
			content: image
		})
		marker.addListener('click', function () {
			for (let i = 0; i < infoWindows.value.length; i++) {
				infoWindows.value[i].close()
			}
			infowindow.open(map, marker)
		})

	})

	document.body.addEventListener('click', (event: Event) => {
		if (event.target && event.target.matches('.js-shop-link')) {
			const marker: MarkerData = dealers.value.find(d => d.MenuText === event.target.dataset.dealer)
			setShop(marker)
		}
	})

	let bounds = new google.maps.LatLngBounds()
	for (let i = 0; i < dealers.value.length; i++) {
		if (dealers[i]) {
			bounds.extend(dealers[i].getPosition())
		}
	}
}

const getDealers = async () => {
	return fetch(mapsData.endpoint).then(resp => resp.json())
}

const loadMap = () => {
	const googleMapsScript = document.createElement('script')
	googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${mapsData.apiKey}&callback=initMap`
	googleMapsScript.async = true
	window.initMap = initMap
	document.head.appendChild(googleMapsScript)

	mapLoaded.value = true
}

onMounted(() => {
	getDealers().then((markersData: MarkerData[]) => {
		markersData?.shops?.forEach((markerData: MarkerData) => {
			dealers?.value.push(markerData)
		})
		if (Cookiebot.consent.marketing && Cookiebot.consent.statistics) {
			loadMap()
		} else {
			window.addEventListener('CookiebotOnAccept', function () {
				if (Cookiebot.consent.marketing && Cookiebot.consent.statistics) {
					loadMap()
				}
			}, false)
		}
	})
})
</script>

<template>
	<div id="maps">
		<div class="container mb-8">
			<div
				ref="mapContainer"
				class="block min-h-[40rem] all-shops cookieconsent-optin-statistics cookieconsent-optin-marketing">
			</div>
			<div
				v-if="!mapLoaded"
				class="flex flex-col mb-5 p-5 border cookieconsent-optout-statistics cookieconsent-optout-marketing">
				<div class="p-2 text-center">{{ getTranslation('BlockedContentAllowCookiesText') }}</div>
				<div class="p-2 text-center">
					<a href="javascript:Cookiebot.renew()">{{ getTranslation('ChangeCookieSettingsButtonText') }}</a>
				</div>
			</div>
		</div>

		<div class="bg-grey-light py-8">
			<div class="container">
				<div class="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
					<DealerCard
						v-for="dealer in dealers"
						:key="dealer.ShopName"
						:dealer="dealer"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
