<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'
import YouTubePlayer from 'youtube-player'
import { getTranslation } from '@/ts/utilities.ts'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))

defineExpose({ stopVideo })

declare let Cookiebot: any;
let player: any;

interface Props {
	video: string,
	play?: boolean
	context?: string,
	orientation?: string
}

const props = defineProps<Props>()

const video = ref(null)
const videoPoster = ref<HTMLDivElement | null>(null)
const videoId = props.video

let aspectClass = props.orientation == 'portrait' ? 'aspect-[9/16]' : 'aspect-[16/9]';

function startVideo(video: any, videoId: string) {
	player = YouTubePlayer(video, {
		videoId,
		playerVars: {
			autoplay: 1,
			controls: 1,
			rel: 0,
			enablejsapi: 1,
			origin: window.location.origin,
			mute: 1
		}
	})
	player.playVideo()
}

function stopVideo() {
	if(video.value && player){
		player
			.pauseVideo()
			.then(() => {
				console.log('video paused')
			})
	}
}

const getPosters = new Promise((resolve) => {
	let source: string = `https://img.youtube.com/vi_webp/${videoId}/maxresdefault.webp`;
	resolve(source)
})

// Poster handling
function setPoster(source: string) {
  let poster = new Image();
  poster.src = source;
  poster.width = 1280;
  poster.height = 720;
  poster.alt = '';

  if (props.orientation == 'portrait') {
	  poster.width = 720;
	  poster.height = 1280;
  }
  poster.classList.add('w-full', 'h-full', 'object-cover', 'rounded');

  if (props.context != 'carousel') {
	  poster.classList.add(aspectClass);
  }
  return new Promise((resolve) => {
	  resolve(poster);
  });
}

const renewCookies = () => {
  Cookiebot.renew()
}

onMounted(() => {
	if (props.play) {
		startVideo(video.value, videoId)
	} else {
		getPosters.then((source) => {
			setPoster(source).then((poster) => {
				videoPoster.value?.appendChild(poster)
			})
		})
	}
})
</script>

<template>
  <div class="relative w-full"
  	:class="props.context == 'carousel' ? 'h-full' : ''">
    <div class="video-wrapper w-full h-full"
		 :class="props.context == 'carousel' ? '' : aspectClass"
		 ref="video">
      <div class="video-poster absolute inset-0"
		   ref="videoPoster"
	  >
		  <div class="absolute inset-0 flex items-center justify-center bg-black/25 rounded z-10">
			  <div class="cookieconsent-optin-marketing">
				  <RenderAction
					  action="button"
					  theme="white"
					  size="sm"
					  @click="startVideo(video, props.video)"
				  >
					  <font-awesome-icon :icon="['fal', 'play']"/>
					  {{ getTranslation('Product.WatchVideo') }}
				  </RenderAction>
			  </div>
			  <template class="cookieconsent-optout-marketing">
				  <div class="absolute inset-0 p-4 w-full h-full object-fill bg-white/75 flex flex-col items-center justify-center text-center text-pretty">
					  {{ getTranslation('BlockedContentAllowCookiesText') }}
					  <RenderAction
						  action="button"
						  theme="white"
						  size="sm"
						  @click="renewCookies"
					  >
						  {{ getTranslation('ChangeCookieSettingsButtonText') }}
					  </RenderAction>
				  </div>
			  </template>
		  </div>
	  </div>
    </div>
  </div>
</template>
